.demo-desc {
  margin-top: 30px;
}
.card-box {
  height: 160px;
}
.muti-image-item {
  position: relative;
}
.muti-image-item .delete-image-item {
  position: absolute;
  right: -20px;
  top: 0;
}
