.map-div {
  height: 400px;
  width: 100%;
  padding: 0px;
  margin: 0px;
  position: relative;
}
#container {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}
.search-box {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 280px;
}
.search-box .search-input {
  width: 192px;
}
#panel {
  position: absolute;
  background-color: white;
  max-height: 80%;
  overflow-y: auto;
  top: 60px;
  right: 10px;
  width: 280px;
}
.map-title {
  position: absolute;
  z-index: 1;
  width: 550px;
  margin: 20px;
}
.map-title .map-iput {
  width: 220px;
}
