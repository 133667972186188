.chat-body {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  /* 设置滚动条的样式 */
  /* 滚动槽 */
  /* 滚动条滑块 */
}
.chat-body .chat-userlist {
  height: 600px;
  background-color: #ffffff;
  /* 设置滚动条的样式 */
  /* 滚动槽 */
  /* 滚动条滑块 */
}
.chat-body .chat-userlist .search-input-box {
  width: 100%;
  padding: 16px;
  text-align: center;
}
.chat-body .chat-userlist .message-box {
  height: 470px;
  overflow-y: scroll;
}
.chat-body .chat-userlist .message-box .user-item .message-line-chat {
  margin-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.chat-body .chat-userlist .message-box .user-item .ant-list-item-action {
  margin-left: 10px;
}
.chat-body .chat-userlist .message-box .user-item:hover {
  background-color: #fff9f1;
}
.chat-body .chat-userlist .message-box::-webkit-scrollbar {
  width: 6px;
}
.chat-body .chat-userlist .message-box::-webkit-scrollbar-track {
  border-radius: 6px;
}
.chat-body .chat-userlist .message-box::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);
}
.chat-body .chat-content {
  height: 600px;
  /* 设置滚动条的样式 */
  /* 滚动槽 */
  /* 滚动条滑块 */
}
.chat-body .chat-content .message-list {
  height: 400px;
  margin: 15px;
  overflow-y: scroll;
}
.chat-body .chat-content .message-list .msg-item .msg-user-title {
  margin: 0;
  padding: 0;
}
.chat-body .chat-content .message-list .msg-item .msg-user-title .msg-username {
  color: #b1b2b4;
}
.chat-body .chat-content .message-list .msg-item .msg-content-box {
  margin-bottom: 20px;
}
.chat-body .chat-content .message-list .msg-item .msg-content-box .msg-content-text {
  display: inline-block;
  margin: 0 30px;
  background-color: #ffffff;
  padding: 10px 15px;
  border-radius: 6px;
}
.chat-body .chat-content .message-list .msg-me {
  text-align: right;
}
.chat-body .chat-content .message-list::-webkit-scrollbar {
  width: 6px;
}
.chat-body .chat-content .message-list::-webkit-scrollbar-track {
  border-radius: 6px;
}
.chat-body .chat-content .message-list::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);
}
.chat-body .chat-content .message-send-box {
  height: 170px;
  background-color: #ffffff;
  border-radius: 10px;
  margin: 15px;
}
.chat-body .chat-content .message-send-box .message-action-icon {
  padding: 15px 0;
}
.chat-body .chat-content .message-send-box .message-action-icon .icon-item {
  font-size: 20px;
  color: #767676;
  margin-left: 20px;
}
.chat-body .chat-content .message-send-box .message-input {
  position: relative;
}
.chat-body .chat-content .message-send-box .message-input .message-input-text {
  border: none;
  height: 110px;
  padding: 0 20px;
}
.chat-body .chat-content .message-send-box .message-input .message-input-text:focus {
  border: none;
  box-shadow: none;
}
.chat-body .chat-content .message-send-box .message-input .send-button {
  position: absolute;
  bottom: 0;
  right: 5px;
}
.chat-body .chat-workbench {
  height: 600px;
  border-left: 1px solid #e8e8e8;
  overflow-y: scroll;
}
.chat-body .chat-workbench .chat-user-order {
  background-color: #ffffff;
  margin: 10px 0;
  padding: 10px;
}
.chat-body .chat-workbench::-webkit-scrollbar {
  width: 6px;
}
.chat-body .chat-workbench::-webkit-scrollbar-track {
  border-radius: 6px;
}
.chat-body .chat-workbench::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.1);
}
