.chat-body {
    background-color: #f4f4f4;
    border: 1px solid #f4f4f4;

    .chat-userlist {
        height: 600px;
        background-color: #ffffff;


        .search-input-box {
            width: 100%;
            padding: 16px;
            text-align: center;
        }

        .message-box {
            height: 470px;
            overflow-y: scroll;

            .user-item {
                .message-line-chat {
                    margin-left: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .ant-list-item-action {
                    margin-left: 10px;
                }
            }

            .user-item:hover {
                background-color: #fff9f1;
            }

        }

        /* 设置滚动条的样式 */
        .message-box::-webkit-scrollbar {
            width: 6px;
        }

        /* 滚动槽 */
        .message-box::-webkit-scrollbar-track {
            border-radius: 6px;
        }

        /* 滚动条滑块 */
        .message-box::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: rgba(0, 0, 0, 0.1);
        }
    }

    .chat-content {
        height: 600px;


        .message-list {
            height: 400px;
            margin: 15px;
            overflow-y: scroll;

            .msg-item {

                .msg-user-title {
                    margin: 0;
                    padding: 0;

                    .msg-username {
                        color: #b1b2b4;
                    }
                }

                .msg-content-box {
                    margin-bottom: 20px;

                    .msg-content-text {
                        display: inline-block;
                        margin: 0 30px;
                        background-color: #ffffff;
                        padding: 10px 15px;
                        border-radius: 6px;
                    }

                }
            }

            .msg-me {
                text-align: right;
            }
        }

        /* 设置滚动条的样式 */
        .message-list::-webkit-scrollbar {
            width: 6px;
        }

        /* 滚动槽 */
        .message-list::-webkit-scrollbar-track {
            border-radius: 6px;
        }

        /* 滚动条滑块 */
        .message-list::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: rgba(0, 0, 0, 0.1);
        }

        .message-send-box {
            height: 170px;
            background-color: #ffffff;
            border-radius: 10px;
            margin: 15px;

            .message-action-icon {
                padding: 15px 0;

                .icon-item {
                    font-size: 20px;
                    color: #767676;
                    margin-left: 20px;
                }
            }

            .message-input {
                position: relative;

                .message-input-text {
                    border: none;
                    height: 110px;
                    padding: 0 20px;
                }

                .message-input-text:focus {
                    border: none;
                    box-shadow: none;
                }

                .send-button {
                    position: absolute;
                    bottom: 0;
                    right: 5px;
                }
            }
        }
    }

    .chat-workbench {
        height: 600px;
        border-left: 1px solid #e8e8e8;
        overflow-y: scroll;


        .chat-user-order {
            background-color: #ffffff;
            margin: 10px 0;
            padding: 10px;
        }
    }

    /* 设置滚动条的样式 */
    .chat-workbench::-webkit-scrollbar {
        width: 6px;
    }

    /* 滚动槽 */
    .chat-workbench::-webkit-scrollbar-track {
        border-radius: 6px;
    }

    /* 滚动条滑块 */
    .chat-workbench::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.1);
    }
}
@primary-color: #7f7fd5;@link-color: #86a8e7;