
.demo-desc {
  margin-top: 30px;
}
.card-box {
  height: 160px;
}
.muti-image-item {
  position: relative;
  .delete-image-item {
    position: absolute;
    right: -20px;
    top: 0;
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;